window.app.filter('stripTags', ['$sce', function ($sce) {
    return function (text) {
        if (isString(text)) {
            return $sce.trustAsHtml(strip_tags($('<span/>').html(text).text()));
        } else {
            return text;
        }
    };
}]);

window.app.filter("asDate", function () {
    return function (input) {
        if (isString(input)) {
            return new Date(input.replace(/-/g, "/"));
        } else {
            return input;
        }
    };
});

window.app.filter("asInt", function () {
    return function (input) {
        return input ? parseInt(input, 10) : 0;
    };
});

window.app.filter("asFloat", function () {
    return function (input) {
        return input ? parseFloat(input) : 0.0;
    };
});

window.app.filter("asDutchNumber", function () {
    return function (input) {
        return number_format(input, 2, ',', '.');
    };
});

window.app.directive('stringToHtml', ['$sce', function ($sce) {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
            ngModel.$parsers.push(function (val) {
                return val;
            });
            ngModel.$formatters.push(function (val) {
                return $sce.trustAsHtml(val);
            });
        }
    };
}]);

window.app.directive('boolToIntString', function () {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
            ngModel.$parsers.push(function (val) {
                return (val === true || val === '1') ? true :  false;
            });
            ngModel.$formatters.push(function (val) {
                return (val === true || val === '1') ? '1' : '0';
            });
        }
    };
});

window.app.directive('convertToInt', function () {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
            ngModel.$parsers.push(function (val) {
                return val ? parseInt(val, 10) : null;
            });
            ngModel.$formatters.push(function (val) {
                return val ? '' + val : null;
            });
        }
    };
});

window.app.directive('convertToFloat', function () {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
            ngModel.$parsers.push(function (val) {
                return val ? parseFloat(val) : null;
            });
            ngModel.$formatters.push(function (val) {
                return val ? '' + val : null;
            });
        }
    };
});

window.app.directive('stringToNumber', function() {
    return {
        require: 'ngModel',
        link: function(scope, element, attrs, ngModel) {
            ngModel.$parsers.push(function(value) {
                return '' + value;
            });
            ngModel.$formatters.push(function(value) {
                return parseFloat(value);
            });
        }
    };
});

window.app.directive('stringToDate', function() {
    return {
        require: 'ngModel',
        link: function(scope, element, attrs, ngModel) {
            ngModel.$parsers.push(function(value) {
                return '' + value;
            });
            ngModel.$formatters.push(function(value) {
                return new Date(value.toString().replace(/-/g, "/"));
            });
        }
    };
});

window.app.directive('stringToBool', function() {
    return {
        require: 'ngModel',
        link: function(scope, element, attrs, ngModel) {
            ngModel.$parsers.push(function(value) {
                if(value === '1' || value === 1 || value === true || value === 'true') {
                    return true;
                } else {
                    return false;
                }
            });
            ngModel.$formatters.push(function(value) {
                if(value === '1' || value === 1 || value === true || value === 'true') {
                    return '1';
                } else {
                    return '0';
                }
            });
        }
    };
});

window.app.directive('removeImgOnError', function () {
    return {
        link: function (scope, element, attrs) {
            scope.$watch(function () {
                return attrs.ngSrc;
            }, function (value) {
                if (!value) {
                    element.remove();
                }
            });

            element.bind('error', function () {
                element.remove();
            });
        }
    };
});

window.app.directive('jsonToObject', function () {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
            ngModel.$parsers.push(function (value) {
                return jQuery.parseJSON(value);
            });
            ngModel.$formatters.push(function (value) {
                return '' + value;
            });
        }
    };
});

window.app.directive('ngInitial', ['$parse', function($parse) {
    return {
        restrict: "A",
        compile: function ($element, $attrs) {
            if ($element.attr('type') == 'checkbox') {
                var initialValue = $element.is(":checked");
            } else {
                var initialValue = $attrs.value || $element.val();
            }

            if ($element.attr('type') !== 'radio' || $element.is(":checked")) {
                return {
                    pre: function ($scope, $element, $attrs) {
                        $parse($attrs.ngModel).assign($scope, initialValue);
                    }
                }
            }
        }
    }
}]);
