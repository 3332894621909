'use strict';

import angular from 'angular';
import ipCookie from 'angular-cookie';

window.app = angular.module('webshopApp', ['app.services', 'app.controllers']).config([
    '$compileProvider', function ($compileProvider) {
        $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|app\.skgcollect\.[a-z]+|app\.multoweb\.[a-z]+):/);
    }
]);

window.appServices = angular.module('app.services', ['app.api.config']);

window.appControllers = angular.module('app.controllers', ['ipCookie']);

window.appServices.factory("api", ['$http', 'API_URL', 'JWT_TOKEN', function ($http, API_URL, JWT_TOKEN) {

    var config = {
        headers: {
            "X-Jwt-Token": JWT_TOKEN
        }
    };

    var serviceBase = API_URL + '/v1/';
    var obj = {};

    return obj;
}]);
